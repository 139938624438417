import React from 'react'
import ReactDOM from 'react-dom'
import Root from './components/root'
import { fromJson } from './options'

const init = () => {
  const main = document.getElementById('main')
  if (!main) return console.error('#main element not found')
  const options = fromJson(JSON.parse(main.innerText))
  const root = document.createElement('div')
  main.replaceWith(root)

  ReactDOM.render(
    <Root options={options} />,
    root
  )
}

init()
