import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import loginBackground from '../../assets/img/login-bg.svg'
import makeStyles from '@material-ui/core/styles/makeStyles'

const AuthLayout: React.FC = (props) => {
  const styles = useStyles()

  return (
    <Box className={styles.background}>
      <Card raised={true}>
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            padding={8}
            width='500px'
          >
            {props.children}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default AuthLayout

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
