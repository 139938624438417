import React from 'react'
import Typography from '@material-ui/core/Typography'
import fatalError from '../../assets/img/fatal-error.svg'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import LocationContext from '../contexts/location'

const AuthFatalError: React.FC = (props) => {
  const location = React.useContext(LocationContext)

  return (
    <>
      <Box mb={2}><img src={fatalError} alt='Error' /></Box>
      <Typography variant='h4' component='h2' color='primary'>
       Something went wrong!
      </Typography>
      <Box component='p' textAlign='center' mb={4}>
        <Box component='span' display='block'>An error occurred while loading this page.</Box>
        <Box component='span' display='block'>Please refresh the page and try again.</Box>
      </Box>
      <Button variant='contained' color='primary' onClick={() => location.reload()}>
       Refresh
      </Button>
      <Box mt={4}>
        <Paper variant='outlined'>
          <Box p={1}>
            <Typography variant='subtitle2'>Error Log</Typography>
          </Box>
          <Divider />
          <Box p={2}>{props.children}</Box>
        </Paper>
      </Box>
    </>
  )
}

export default AuthFatalError
