import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { theme } from '../../shared/theme'
import { Result } from '../../shared/result'
import { Options } from '../options'
import Page from './page'

const Root: React.FC<{ options: Result<Error, Options> }> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Page options={props.options} />
    </ThemeProvider>
  )
}

export default Root
