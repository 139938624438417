import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import bizzkit from '../../assets/img/bizzkit.svg'
import LocationContext from '../../shared/contexts/location'
import { ExternalProvider, Options } from '../options'
import ProviderIcon from './provider-icon'

const ProviderSelection: React.FC<{ options: Options }> = (props) => {
  const styles = useStyles()
  const location = React.useContext(LocationContext)

  const redirectToProvider = (externalProvider: ExternalProvider) => {
    const provider = encodeURIComponent(externalProvider.authenticationScheme)
    const returnUrl = props.options.returnUrl ? encodeURIComponent(props.options.returnUrl) : ''
    location.href = `/external/challenge?provider=${provider}&returnUrl=${returnUrl}`
  }

  return (
    <>
      <img src={bizzkit} alt='Bizzkit' />
      <Box my={3} textAlign='center'>Sign in to Bizzkit, the only e-commerce platform which combines PIM, DAM and CMS</Box>
      <List>
        {props.options.visibleExternalProviders.map(provider => (
          <ListItem key={provider.displayName}>
            <Button
              startIcon={<ProviderIcon provider={provider} />}
              variant='outlined'
              fullWidth
              classes={{
                label: styles.providerLabel
              }}
              onClick={() => redirectToProvider(provider)}
            >
              Sign In With {provider.displayName}
            </Button>

          </ListItem>
        ))}
      </List>
    </>
  )
}

export default ProviderSelection

const useStyles = makeStyles(theme => ({
  providerLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: theme.spacing(1)
  }
}))
