import React from 'react'
import { Result } from '../../shared/result'
import { Options } from '../options'
import AuthFatalError from '../../shared/components/auth-fatal-error'
import ProviderSelection from './provider-selection'
import AuthLayout from '../../shared/components/auth-layout'

const Page: React.FC<{ options: Result<Error, Options> }> = (props) => {
  return (
    <AuthLayout>
      {props.options.hasError && (
        <AuthFatalError>{props.options.error.message}</AuthFatalError>
      )}
      {!props.options.hasError && (
        <ProviderSelection options={props.options.data} />
      )}
    </AuthLayout>
  )
}

export default Page
