import React from 'react'
import facebookIcon from '../../assets/img/providers/facebook.svg'
import office365Icon from '../../assets/img/providers/office365.svg'
import googleIcon from '../../assets/img/providers/google.svg'
import auth0Icon from '../../assets/img/providers/auth0.svg'
import unknownIcon from '../../assets/img/providers/unknown.svg'
import { ExternalProvider } from '../options'

const ProviderIcon: React.FC<{ provider: ExternalProvider }> = (props) => {
  const icon = getIconFor(props.provider.authenticationScheme)

  return (
    <img src={icon} alt={props.provider.displayName} />
  )
}

export default ProviderIcon

const getIconFor = (authenticationScheme: string): string => {
  switch (authenticationScheme) {
    case 'Auth0':
      return auth0Icon

    case 'Google':
      return googleIcon

    case 'Facebook':
      return facebookIcon

    case 'Microsoft':
      return office365Icon

    default:
      return unknownIcon
  }
}
