import * as decode from 'decoders'
import result, { Result } from '../shared/result'

export type Options = {
  returnUrl: null | string
  visibleExternalProviders: ExternalProvider[]
}

export type ExternalProvider = {
  displayName: string
  authenticationScheme: string
}

export const fromJson = (json: any): Result<Error, Options> => {
  try {
    const options = decode.guard(decoder)(json)
    return result.succeed(options)
  } catch (error) {
    return result.fail(error)
  }
}

const decoder: decode.Decoder<Options> = decode.object({
  returnUrl: decode.nullable(decode.string),
  visibleExternalProviders: decode.array(decode.object({
    displayName: decode.string,
    authenticationScheme: decode.string
  }))
})
